<template>
  <div>
       <section class="footer">
     <div class="container">
       <div class="row">
         <div class="col-md-12 footerarea">
           <div class="row">
             <div class="col-md-12 imgarea">
              <img :src="Belediyebilgiler.kapak" alt="">
             </div>
             <div class="col-md-12 contact mt-5 mb-5">
              <h4>İLETİŞİM MERKEZLERİ</h4>
              <p>Belediye Santral: {{ Belediyebilgiler.iletisim }}</p>
              <p>Çağrı Merkezi: {{ Belediyebilgiler.iletisim }}</p>
              <p>Zabıta İletişim Hattı: {{ Belediyebilgiler.iletisim }}</p>
             </div>
             <div class="col-md-12 billboard">
              <div class="col-4 socialarea">
                <ul>
                  <li>
                    <a target="_blank" :href="Belediyebilgiler.face">
                      <svg enable-background="new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><path d="m256 152c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm181-152h-362c-41.4 0-75 33.6-75 75v362c0 41.4 33.6 75 75 75h362c41.4 0 75-33.6 75-75v-362c0-41.4-33.6-75-75-75zm-181 392c-74.399 0-135-60.601-135-135 0-74.401 60.601-135 135-135s135 60.599 135 135c0 74.399-60.601 135-135 135zm165-270c-16.5 0-30-13.5-30-30s13.5-30 30-30 30 13.5 30 30-13.5 30-30 30zm-165 30c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105zm0 0c-57.9 0-105 47.1-105 105s47.1 105 105 105 105-47.1 105-105-47.1-105-105-105z"/></svg>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" :href="Belediyebilgiler.insta">
                      <svg id="Bold" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"/></svg>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" :href="Belediyebilgiler.twit">
                      <svg id="Bold" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m21.534 7.113c.976-.693 1.797-1.558 2.466-2.554v-.001c-.893.391-1.843.651-2.835.777 1.02-.609 1.799-1.566 2.165-2.719-.951.567-2.001.967-3.12 1.191-.903-.962-2.19-1.557-3.594-1.557-2.724 0-4.917 2.211-4.917 4.921 0 .39.033.765.114 1.122-4.09-.2-7.71-2.16-10.142-5.147-.424.737-.674 1.58-.674 2.487 0 1.704.877 3.214 2.186 4.089-.791-.015-1.566-.245-2.223-.606v.054c0 2.391 1.705 4.377 3.942 4.835-.401.11-.837.162-1.29.162-.315 0-.633-.018-.931-.084.637 1.948 2.447 3.381 4.597 3.428-1.674 1.309-3.8 2.098-6.101 2.098-.403 0-.79-.018-1.177-.067 2.18 1.405 4.762 2.208 7.548 2.208 8.683 0 14.342-7.244 13.986-14.637z"/></svg>
                    </a>
                  </li>
                </ul>
               </div>
              <div class="col-8 textarea">
                {{Baskanbilgisi.baskanslogan}}
              </div>
             </div>
             <div class="col-md-12 menufooter">
              <ul>
                <li>
                    <a>
                      <router-link to="/duyuruIlanlar">
                         Duyurular
                      </router-link> 
                    </a>
                </li>
                <li>
                    <a>
                       <router-link to="/belgeler">
                        Belge Örnekleri
                      </router-link> 
                    </a>
                </li>
                <li>
                    <a>
                       <router-link to="/raporlar">
                        Faaliyet Raporları
                      </router-link>
                    </a>
                </li>
                <li>
                    <a>
                      <router-link to="/kentrehberi">
                      Kent Rehberi
                      </router-link> 
                    </a>
                </li>
                <li>
                    <a>
                      <router-link to="/kvkk">
                      KVKK Aydınlatma Metni
                      </router-link> 
                    </a>
                </li>
                <li>
                    <a>
                      <router-link to="/meclisuyeleri">
                      Meclis Üyeleri
                      </router-link>
                    </a>
                </li>
              </ul>
            </div>
           </div>
         </div>
       </div>
     </div>
   </section>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      Belediyebilgiler: {},
      Baskanbilgisi: {},
    };
  },
  methods: {
    async baskancek() {
      await this.$store.dispatch("vericekme", "Baskan");
      this.Baskanbilgisi = this.$store.getters.getBaskan;
    },
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    await this.baskancek();
  },
};
</script>
<style>
#footerMenu ul li a {
  color: #fff;
  text-decoration: none;
}
#footerMenu ul li a:hover {
  color: #fff !important;
  border-bottom: 1px solid #fff;

  border-left: 15px solid #fff;

  padding-left: 10px;
  transition: all 0.4s ease;
}
</style>