<template>
  <div>
        <header class="header">
      <div class="container">
        <div class="row">
          <div class="col">
            <nav class="navbar navbar-expand-lg">
              <div class="container-fluid">
                <a class="navbar-brand" href="/">
                  <img :src="Belediyebilgiler.kapak" alt="">
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon">
                    <svg enable-background="new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                      <g fill="#FF485A"><path d="m479.18 91.897h-446.36c-18.131 0-32.821-14.69-32.821-32.82s14.69-32.821 32.821-32.821h446.36c18.13 0 32.82 14.69 32.82 32.821s-14.69 32.82-32.82 32.82z" fill="#eee"/><path d="M295.385,288.821H32.821C14.69,288.821,0,274.13,0,256s14.69-32.821,32.821-32.821h262.564   c18.13,0,32.821,14.69,32.821,32.821S313.515,288.821,295.385,288.821z" fill="#fff"/></g><path d="m479.18 288.82h-52.513c-18.13 0-32.821-14.69-32.821-32.821s14.69-32.821 32.821-32.821h52.513c18.13 0 32.82 14.69 32.82 32.821s-14.69 32.821-32.82 32.821z" fill="#eee"/><path d="m479.18 485.74h-446.36c-18.131 0-32.821-14.691-32.821-32.821s14.69-32.821 32.821-32.821h446.36c18.13 0 32.82 14.69 32.82 32.821 0 18.13-14.69 32.821-32.82 32.821z" fill="#fff"/></svg>
                  </span>
                </button>
                <div class="collapse navbar-collapse flex-grow-0" id="navbarSupportedContent">
                  <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        KURUMSAL
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><router-link class="dropdown-item" to="/birimler"> Birimler</router-link></li>
                        <li><router-link class="dropdown-item" to="/meclisuyeleri">Meclis Üyeleri</router-link></li>
                        <li><router-link class="dropdown-item" to="/encumenlarimiz">Encümenlerimiz</router-link></li>
                        <li><router-link class="dropdown-item" to="/kararlar">Meclis Kararları</router-link></li>
                        <li><router-link class="dropdown-item" to="/raporlar">Faaliyet Raporları</router-link></li>
                        <li><router-link class="dropdown-item" to="/raporlar">Meclis Gündemleri</router-link></li>
                        <li><router-link class="dropdown-item" to="/tarihce">Etik Komisyonu</router-link></li>
                        <li><router-link class="dropdown-item" to="/kvkk">KVKK Aydınlatma Metni</router-link></li>
                      </ul>
                    </li>
                    <li class="nav-item">
                     <router-link class="nav-link active" aria-current="page" href="" to="/duyuruIlanlar">
                        HİZMETLER
                     </router-link>
                    </li>
                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        BAŞKAN
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li> 
                          <router-link class="dropdown-item" to="/ozgecmis"> Başkan Özgeçmiş</router-link>
                        </li>
                        <li> 
                          <router-link class="dropdown-item" to="/mesaj"> Başkan Mesaj</router-link>
                        </li>
                        <li> 
                          <router-link class="dropdown-item" to="/baskangaleri"> Başkan Galeri</router-link>
                        </li>
                        <li> 
                          <router-link class="dropdown-item" to="/baskanasor"> Banşkan'a Sor</router-link>
                        </li>
                        <li> 
                          <router-link class="dropdown-item" to="/eskibaskanlar"> Belediye Başkanlarımız</router-link>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link active" aria-current="page" href="" to="/haberler">
                      HABERLER
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link  class="nav-link active" aria-current="page" href="" to="/kararlar">
                      E-BELEDİYE
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      Belediyebilgiler: {},
      domAd: "",
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    this.domAd = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
};
</script>

<style>
.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #217c3d;
}
</style>